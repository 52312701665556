import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { deleteInvoice, getRestaurantInventoryCounts, updateInventoryCount } from '../api/inventory-counts';
import { useToast } from '../common/components';
import { Paginated } from '../common/models';
import { HttpException } from '../common/models/httpException';
import { useTranslations } from '../contexts/LocalizationContext';
import { InventoryCountResponse } from '../modules/Restaurants/InventoryCounts/models/inventory-count-response';
import { InventoryCountUpdateRequest } from '../modules/Restaurants/InventoryCounts/models/inventory-count-update-request';

export const CK_INVENTORY_COUNTS = 'restaurant-inventory-counts';

export const useRestaurantInventoryCounts = (
  restaurantId: string,
  offset: number,
  limit: number,
): QueryObserverResult<Paginated<InventoryCountResponse>, AxiosError<HttpException>> => {
  return useQuery(
    [CK_INVENTORY_COUNTS, { offset, limit }],
    () => getRestaurantInventoryCounts(restaurantId, limit, offset),
    {
      keepPreviousData: true,
    },
  );
};

export const useUpdateRestaurantInventoryCount = (): UseMutationResult<
  InventoryCountResponse,
  AxiosError<HttpException>,
  { values: InventoryCountUpdateRequest; inventoryCountId: string }
> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation(({ values, inventoryCountId }) => updateInventoryCount(inventoryCountId, values), {
    onSuccess: async () => {
      displayToast('success', translations('inventory_counts_inventory_count_update_success'), false, 3000);
      await queryClient.invalidateQueries(CK_INVENTORY_COUNTS);
    },
  });
};

export const useDeleteInvoice = (): UseMutationResult<void, AxiosError<HttpException>, string> => {
  const translations = useTranslations();
  const { displayToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation((inventoryCountId) => deleteInvoice(inventoryCountId), {
    onSuccess: async () => {
      displayToast('success', translations('inventory_counts_invoice_delete_success'), false, 3000);
      await queryClient.invalidateQueries(CK_INVENTORY_COUNTS);
    },
    onError: (error) => displayToast('error', error.response?.data.message || translations('something_went_wrong')),
  });
};
