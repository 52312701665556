import React, { ChangeEvent, useCallback } from 'react';
import { ArrowUpIcon, DeleteIcon } from '@chakra-ui/icons';
import { Button, Flex, Icon, Image, InputGroup, Tooltip } from '@chakra-ui/react';

import { LinkButton } from '../../../../../common/components';
import { useExecuteWithConfirmation } from '../../../../../common/utils';
import { useTranslations } from '../../../../../contexts/LocalizationContext';
import { useDeleteInvoice, useUpdateRestaurantInventoryCount } from '../../../../../services/inventory-counts';

const getIconPath = (iconName: string) => require(`../../../../../assets/icons/${iconName}`);

type InventoryCountsInvoiceProps = {
  inventoryCountId: string;
  invoiceFileUrl: string | null;
};
export const InventoryCountsInvoice = ({ inventoryCountId, invoiceFileUrl }: InventoryCountsInvoiceProps) => {
  const translations = useTranslations();
  const { mutate: updateRestaurantInventoryCountStatus, isLoading: isUploadingInvoice } =
    useUpdateRestaurantInventoryCount();
  const { mutate: deleteInvoice } = useDeleteInvoice();
  const { executeWithConfirmation: onDeleteInvoice } = useExecuteWithConfirmation(
    translations('inventory_counts_invoice_delete_confirmation'),
    deleteInvoice,
  );

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length) {
        updateRestaurantInventoryCountStatus({
          values: { invoice: event.target.files[0] },
          inventoryCountId,
        });
      }
    },
    [inventoryCountId, updateRestaurantInventoryCountStatus],
  );

  const getRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  const handleClick = useCallback(() => inputRef.current?.click(), []);
  const handleDeleteInvoice = useCallback(() => onDeleteInvoice(inventoryCountId), [inventoryCountId, onDeleteInvoice]);

  if (invoiceFileUrl) {
    return (
      <Flex>
        <LinkButton data-testid={'invoice'} isDownload to={invoiceFileUrl} variant={'transparent'} px={0}>
          <Image cursor="pointer" src={getIconPath('pdf_icon.svg')} />
        </LinkButton>
        <Tooltip label={translations('delete')}>
          <DeleteIcon data-testid="delete-icon" cursor="pointer" onClick={handleDeleteInvoice} ml={2} my={'auto'} />
        </Tooltip>
      </Flex>
    );
  } else {
    return (
      <InputGroup onClick={handleClick}>
        <input onChange={handleUpload} type="file" hidden accept={'application/pdf'} ref={getRef} />
        <Button isLoading={isUploadingInvoice} leftIcon={<Icon as={ArrowUpIcon} />}>
          {translations('upload')}
        </Button>
      </InputGroup>
    );
  }
};
